import Header from "../components/Header"
import Body from "../components/Body"

// Page d'accueil

function Home() {
    return (
    <div>
        <Header />
        <Body />
    </div>
    )
}

export default Home