import Header from "../components/Header"
import Openclassrooms from "../components/Openclassrooms"

// Portfolio

function Portfolio() {
    return (
    <div>
        <Header />
        <Openclassrooms />
    </div>
    )
}

export default Portfolio