import Header from "../components/Header";
import Aboutme from "../components/Aboutme";

// Page A propos

function About() {
    return (
    <div>
        <Header />
        <main>
            <Aboutme/>
        </main>
    </div>
    )
}

export default About